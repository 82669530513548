<template>
  <div class="indexHero">
    <div class="container">
      <div class="indexHero__wrapper">
        <div class="indexHero__left">
          <h1>ITide,<br> <mark>poď aj ty!</mark>
          </h1>
          <ul>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Uč sa od profesionálov
            </li>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Priprav sa na certifikáciu
            </li>
            <li>
              <img
                  src="../assets/images/icons/check-circle.svg"
                  alt=""
                  class="icon"
                  width="25"
                  height="24"
                  loading="lazy"
              >
              Získaj prácu v IT
            </li>
          </ul>
          <BaseButton
              v-if="!loggedIn"
              :is-link="true"
              :href="'/registration'"
          >
            Registrácia zdarma
          </BaseButton>
          <BaseButton
              v-else
              :is-link="true"
              :href="'/courses'"
          >
            Chcem študovať
          </BaseButton>
        </div>
        <div class="indexHero__right">
          <IndexHeroCarousel/>
        </div>
      </div>
<!--      <div class="indexHero__videoBox">-->
<!--        <div class="indexHero__videoBox-arrow">-->
<!--          <picture>-->
<!--            <source-->
<!--                media="(min-width: 992px)"-->
<!--                srcset="../assets/images/indexHero/arrow2.svg"-->
<!--            >-->
<!--            <img-->
<!--                src="../assets/images/indexHero/arrow.svg"-->
<!--                alt=""-->
<!--                class="icon"-->
<!--                width="28"-->
<!--                height="105"-->
<!--                loading="lazy"-->
<!--            >-->
<!--          </picture>-->
<!--          <p>-->
<!--            <mark>Pozri sa,</mark>-->
<!--            aká je cesta k práci v IT-->
<!--          </p>-->
<!--        </div>-->
<!--        <div class="indexHero__videoBox-video">-->
<!--          <a href="#" title="Přehrát video" @click.prevent="modalActive = true">-->
<!--            <picture>-->
<!--              <source-->
<!--                  type="image/webp"-->
<!--                  srcset="../assets/images/indexHero/indexHeroVideo/video.webp"-->
<!--              >-->
<!--              <img-->
<!--                  alt=""-->
<!--                  src="../assets/images/indexHero/indexHeroVideo/video.png"-->
<!--                  class="indexHero__videoBox-video__bg"-->
<!--                  width="387"-->
<!--                  height="217"-->
<!--                  loading="lazy"-->
<!--                  decoding="async"-->
<!--              >-->
<!--            </picture>-->
<!--            <img-->
<!--                src="../assets/images/indexHero/indexHeroVideo/play.svg"-->
<!--                alt=""-->
<!--                class="indexHero__videoBox-video__play"-->
<!--                width="76"-->
<!--                height="76"-->
<!--                loading="lazy"-->
<!--                decoding="async"-->
<!--            >-->
<!--          </a>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
  <BaseModal
      :id="5"
      :is-active="modalActive"
      :size="800"
      @dtc-modal-close="modalActive = false">
    <BaseVideoPlayer
        video-url="https://hls.goodcall.eu/ITJede_video/ITJede_video.m3u8"
        poster="../assets/images/indexHero/indexHeroVideo/video.webp"
        :autoplay="true"
    />
  </BaseModal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import BaseModal from "@/components/BaseModal";
import BaseVideoPlayer from "@/components/BaseVideoPlayer";
import IndexHeroCarousel from "@/components/IndexHeroCarousel";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
    BaseModal,
    BaseVideoPlayer,
    IndexHeroCarousel,
  },
  data() {
    return {
      modalActive: false,
    }
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>