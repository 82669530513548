<template>
  <div class="indexAbout">
    <div class="container container--xs">
      <div class="indexAbout__header">
        <h2 class="h1 indexAbout__header-title">
          Aké kurzy Ťa čakajú?
        </h2>
      </div>
      <div class="indexAbout__body">
        <p>
          Dozvieš sa viac nie len o <mark>Microsoft 365, cloudových službách</mark> alebo <mark>Power Platform</mark>. Stane sa z teba profík na <mark>Cybersecurity</mark>, naučíš sa spracovávať <mark>dátové analýzy</mark> a predstavíme Ti svet <mark>CRM</mark> alebo <mark>ERP</mark>.
        </p>
        <p>
          Okrem toho u nás nájdeš aj kurzy
          zamerané na osobný rozvoj alebo techniky moderného obchodu.
        </p>
      </div>
      <div class="indexAbout__footer">
        <BaseButton
            v-if="!loggedIn"
            :is-link="true"
            :href="'/registration'"
        >
          Registrácia zdarma
        </BaseButton>
        <BaseButton
            v-else
            :is-link="true"
            :href="'/courses'"
        >
          Chcem študovať
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import {mapGetters} from "vuex";

export default {
  components: {
    BaseButton,
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
  },
}
</script>