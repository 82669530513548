<template>
  <BaseLayout>
    <IndexHero/>
    <IndexCounters/>
    <IndexAbout/>
    <IndexReview/>
    <IndexInfo/>
    <indexJobs/>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/BaseLayout.vue'
import IndexCounters from '@/components/IndexCounters.vue'
import IndexAbout from '@/components/IndexAbout.vue'
import IndexHero from '@/components/IndexHero.vue'
import IndexInfo from "@/components/IndexInfo.vue";
import IndexJobs from "@/components/IndexJobs.vue";
import IndexReview from "@/components/IndexReview.vue";

export default {
  components: {
    IndexAbout,
    IndexReview,
    IndexInfo,
    IndexJobs,
    BaseLayout,
    IndexHero,
    IndexCounters,
  }
}
</script>
